.css-eziifo div:not(.css-1umqo6f) {
  background-color: #85b858 !important;
}
.css-118whkv {
  border: 16px solid #85b858 !important;
}
.css-2ujp1m {
  border: 16px solid #85b858 !important;
}
/* AM selected */
.css-92nr1f-MuiButtonBase-root-MuiIconButton-root {
  background-color: #c8678d !important;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  margin-bottom: 100px !important;
}
/* AM not selected */
.css-1qixffb-MuiButtonBase-root-MuiIconButton-root {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  margin-bottom: 100px !important;
}
/* PM selected */
.css-1s220mh-MuiButtonBase-root-MuiIconButton-root {
  background-color: #c8678d !important;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  margin-bottom: 100px !important;
}
/* PM not selected */
.css-1267l58-MuiButtonBase-root-MuiIconButton-root {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  margin-bottom: 100px !important;
}
.css-1s220mh-MuiButtonBase-root-MuiIconButton-root {
  background-color: #c8678d !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #85b858 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
}

.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #c8678d !important;
}

/* after deploy css */
.css-1rcvyyg.Mui-focused {
  color: #c8678d !important;
}
.css-igs3ac {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
}
.css-1bn53lx.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #85b858 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0;
}
.css-1bn53lx:hover .MuiOutlinedInput-notchedOutline {
  border-color: #c8678d !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0;
}
/* AM SELECTED */
.css-cysikj {
  background-color: #c8678d !important;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  margin-bottom: 100px !important;
}
.css-ag8lru {
  background-color: #c8678d;
}
/* AM NOT SELECTED */
.css-3h73n3 {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  margin-bottom: 100px !important;
}
/* PM SELECTED */
.css-ag8lru {
  background-color: #c8678d !important;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  margin-bottom: 100px !important;
}
/* PM NOT SELECTED */
.css-sfp64 {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  margin-bottom: 100px !important;
}
